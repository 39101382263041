import React, { useState, useEffect } from 'react';
import {
  Paper, Button, Box, LinearProgress, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { fetchEmployees } from '../../services/EmployeeService';
import GenericTableView from '../../components/GenericTableView';
import GenericCardView from '../../components/GenericCardView';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useAuth } from '../../context/AuthContext';
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as EyeIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { usePreferences } from '../../context/UserPreferencesContext';
import { useTheme, useMediaQuery } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';


const EmployeeManagementPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(devicePreferences.viewMode ? devicePreferences.viewMode : isMobile ? 'card' : 'table');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEmployees(apiKey, organizationId, token, userId);
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiKey, organizationId, token, userId]);

  const handleRowClick = (employeeId) => {
    navigate(`/employees/detail/${employeeId}`);
  };


  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const transformData = () => ({
    headers: [
      { key: 'name', label: t('employeeManagement.name'), display: { value: true } },
      { key: 'email', label: t('employeeManagement.email'), display: { value: true } },
      { key: 'role', label: t('employeeManagement.role'), display: { value: true } },
    ],
    rows: employees.map((employee) => ({
      id: employee.id,
      name: { value: employee.firstName + ' ' + employee.lastName },
      email: { value: employee.email },
      role: { value: employee.role },
      actions: [
        {
          icon: <EyeIcon />,
          handler: () => navigate(`/employees/view/${employee.id}`),
        },
        {
          icon: <EditIcon />,
          handler: () => navigate(`/employees/detail/${employee.id}`),
          label: t('common.edit'),
        },
        {
          icon: <DeleteIcon />,
          handler: () => handleDelete(employee.id),
          label: t('common.delete'),
        },
      ],
    })),
  });

  const handleDelete = (employeeId) => {
    console.log('Delete employee:', employeeId);
  }

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">{t('employeeManagement.title')}</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/employees/detail/new')}
          >
            {t('employeeManagement.addEmployee')}
          </Button>
          <IconButton onClick={toggleViewMode} color="primary">
            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        viewMode === 'table' ? (
          <GenericTableView structure={transformData()} onClick={handleRowClick} />
        ) : (
          <GenericCardView structure={transformData()} onClick={handleRowClick} />
        )
      )}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default EmployeeManagementPage;
