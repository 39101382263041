// src/components/FarmDesigner/FarmLayout.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Button, Typography, Box } from '@mui/material';
import ReactToPrint from 'react-to-print';
import BoxItem from './BoxItem';
import RoomItem from './RoomItem';
import RoomCreationModal from './RoomCreationModal';
import './FarmLayout.css';
import { fetchAllBoxTypes } from '../../services/BoxService';
import { useAuth } from '../../context/AuthContext';
import { fetchFarmLayout, saveFarmLayout, deleteFarmLayout } from '../../services/FarmLayoutService';
import PrintPreviewModal from './PrintPreviewModal';
import { useTranslation } from 'react-i18next';

const ReactGridLayout = WidthProvider(RGL);

const FarmLayout = React.memo(
    ({ locationId, buildingId, buildingWidth, buildingLength, onBoxTypesChange }) => {
        const { t } = useTranslation();
        const [boxes, setBoxes] = useState([]);
        const [boxTypes, setBoxTypes] = useState([]);
        const [rooms, setRooms] = useState([]);
        const [layout, setLayout] = useState([]);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const { apiKey, organizationId, token: jwtToken } = useAuth();
        const [layoutId, setLayoutId] = useState(null);
        const [isInitializing, setIsInitializing] = useState(true);

        const [boxCount, setBoxCount] = useState(0);
        const [roomCount, setRoomCount] = useState(0);

        const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
        const [isPrintLoading, setIsPrintLoading] = useState(false);

        const printRef = useRef(null);

        const openPrintModal = () => {
            setIsPrintModalOpen(true);
        };

        const closePrintModal = () => {
            setIsPrintModalOpen(false);
        };

        const [isPrintMode, setIsPrintMode] = useState(false);

        // New state variables for layout not found and creating layout
        const [isLayoutNotFound, setIsLayoutNotFound] = useState(false);
        const [isCreatingLayout, setIsCreatingLayout] = useState(false);

        const useLoadBoxTypes = (apiKey, organizationId, jwtToken, locationId, buildingId) => {
            useEffect(() => {
                const loadBoxTypes = async () => {
                    try {
                        const data = await fetchAllBoxTypes(apiKey, organizationId, jwtToken);
                        const validBoxTypes = data.filter(
                            (box) =>
                                box.locationId === locationId && box.buildingId === buildingId
                        );

                        setBoxTypes(validBoxTypes);
                    } catch (err) {
                        console.error('Error fetching box types', err);
                    }
                };
                if (locationId && buildingId) {
                    loadBoxTypes();
                }
            }, [apiKey, organizationId, jwtToken, locationId, buildingId]);
        };

        const handleResetLayout = useCallback(async () => {
            try {
                const defaultBoxes = [];
                const defaultRooms = [];
                const defaultLayout = [];

                // If you want to use the saved initial state from the API:
                if (boxTypes.length > 0) {
                    defaultBoxes.push(
                        ...boxTypes.flatMap((box) =>
                            Array.from({ length: Number(box.amount) }, (_, index) => ({
                                ...box,
                                width: Number(box.width) || 1,
                                length: Number(box.length) || 1,
                                instanceId: `${box.id}-${index}`,
                                type: 'box',
                                horse: null,
                            }))
                        )
                    );

                    let currentX = 0;
                    let currentY = 0;
                    let maxRowHeight = 0;

                    defaultLayout.push(
                        ...defaultBoxes.map((box) => {
                            const boxWidth = Number(box.width) || 1;
                            const boxLength = Number(box.length) || 1;

                            if (currentX + boxWidth > buildingWidthMeters) {
                                currentX = 0;
                                currentY += maxRowHeight;
                                maxRowHeight = 0;
                            }

                            const layoutItem = {
                                i: box.instanceId,
                                x: currentX,
                                y: currentY,
                                w: boxWidth,
                                h: boxLength,
                                static: false,
                            };

                            currentX += boxWidth;
                            if (boxLength > maxRowHeight) {
                                maxRowHeight = boxLength;
                            }

                            return layoutItem;
                        })
                    );
                }

                // Reset states
                setBoxes(defaultBoxes);
                setRooms(defaultRooms);
                setLayout(defaultLayout);
                setBoxCount(defaultBoxes.length);
                setRoomCount(defaultRooms.length);

                // Optionally, save the reset layout to the backend
                await saveFarmLayout({
                    id: layoutId,
                    layoutData: { layout: defaultLayout, boxes: defaultBoxes, rooms: defaultRooms },
                    locationId,
                    buildingId,
                });

                console.log(t('FarmLayout.LayoutResetSuccessfully'));
            } catch (error) {
                console.error(t('FarmLayout.ErrorResettingLayout'), error);
            }
        }, [boxTypes, apiKey, organizationId, jwtToken, locationId, buildingId, t, buildingWidth, layoutId, saveFarmLayout]);


        useLoadBoxTypes(apiKey, organizationId, jwtToken, locationId, buildingId);

        useEffect(() => {
            const loadLayout = async () => {
                try {
                    const data = await fetchFarmLayout(apiKey, organizationId, jwtToken, locationId, buildingId);
                    if (data && data.layoutData && data.layoutData.layout && data.layoutData.layout.length > 0) {
                        // Existing layout found
                        const loadedBoxes = data.layoutData.boxes || [];
                        const loadedRooms = data.layoutData.rooms || [];

                        setLayoutId(data.id);
                        setLayout(data.layoutData.layout);
                        setBoxes(loadedBoxes.map(box => ({
                            ...box,
                            width: Number(box.width) || 1,
                            length: Number(box.length) || 1,
                        })));
                        setRooms(loadedRooms.map(room => ({
                            ...room,
                            width: Number(room.width) || 1,
                            length: Number(room.length) || 1,
                        })));
                        setBoxCount(loadedBoxes.length);
                        setRoomCount(loadedRooms.length);
                    } else {
                        // Layout data is empty, treat as not found
                        setIsLayoutNotFound(true);
                    }
                } catch (error) {
                    const errorCode = error.response?.status || 500;
                    console.error('Error fetching farm layout', error);

                    if (errorCode === 404 && error.response.data === 'Farm layout not found.') {
                        setIsLayoutNotFound(true);
                    } else {
                        // Handle other errors accordingly
                        console.error('An unexpected error occurred while fetching the farm layout.');
                    }
                } finally {
                    setIsInitializing(false);
                }
            };

            if (locationId && buildingId) {
                loadLayout();
            }
        }, [locationId, buildingId, apiKey, organizationId, jwtToken]);

        const saveLayout = useCallback(
            async (dataToSave) => {
                try {
                    if (!dataToSave.layoutData || dataToSave.layoutData.layout.length === 0) {
                        console.error('No layout data to save');
                        return;
                    }

                    const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, dataToSave);

                    if (!layoutId && savedLayout.id) {
                        setLayoutId(savedLayout.id);
                    }

                    console.log(t('FarmLayout.LayoutSavedSuccessfully'));
                } catch (error) {
                    console.error(t('FarmLayout.ErrorSavingLayout'), error);
                }
            },
            [layoutId, apiKey, organizationId, jwtToken, locationId, buildingId, t]
        );

        const filteredItems = useMemo(() => {
            const validBoxes = boxes.filter(
                (box) => box.locationId === locationId && box.buildingId === buildingId
            );
            const validRooms = rooms.filter(
                (room) => room.locationId === locationId && room.buildingId === buildingId
            );
            return [...validBoxes, ...validRooms];
        }, [boxes, rooms, locationId, buildingId]);

        useEffect(() => {
            const buildingWidthMeters = Number(buildingWidth) || 12;
            let currentX = 0;
            let currentY = 0;
            let maxRowHeight = 0;

            const newLayout = filteredItems.map((item, index) => {
                const existingLayoutItem = layout.find((l) => l.i === item.instanceId);
                if (existingLayoutItem) {
                    return existingLayoutItem;
                } else {
                    const itemWidth = Number(item.width) || 1;
                    const itemLength = Number(item.length) || 1;

                    if (currentX + itemWidth > buildingWidthMeters) {
                        currentX = 0;
                        currentY += maxRowHeight;
                        maxRowHeight = 0;
                    }

                    const layoutItem = {
                        i: item.instanceId,
                        x: currentX,
                        y: currentY,
                        w: itemWidth,
                        h: itemLength,
                        static: false,
                    };

                    currentX += itemWidth;
                    if (itemLength > maxRowHeight) {
                        maxRowHeight = itemLength;
                    }

                    return layoutItem;
                }
            });
            setLayout(newLayout);
            const validBoxes = boxes.filter(
                (box) => box.locationId === locationId && box.buildingId === buildingId
            );
            onBoxTypesChange(validBoxes);
        }, [filteredItems, boxCount, roomCount, locationId, buildingId, onBoxTypesChange]);

        const onLayoutChange = useCallback(
            async (newLayout) => {
                setLayout(newLayout);

                const updatedLayoutData = {
                    layout: newLayout,
                    boxes: boxes,
                    rooms: rooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleHorseDrop = useCallback(
            async (instanceId, horse) => {
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse } : box
                );

                setBoxes(updatedBoxes);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const openRoomCreationModal = useCallback(() => {
            setIsModalOpen(true);
        }, []);

        const closeRoomCreationModal = useCallback(() => {
            setIsModalOpen(false);
        }, []);

        const handleCreateRoom = useCallback(
            async (newRoom) => {
                const roomWithLocation = {
                    ...newRoom,
                    amount: '1',
                    autoGenerateInvoice: false,
                    locationId,
                    buildingId,
                    instanceId: `room-${Date.now()}`,
                    id: `room-${Date.now()}`,
                    type: 'room',
                    width: Number(newRoom.width) || 1,
                    length: Number(newRoom.length) || 1,
                };
                const updatedRooms = [...rooms, roomWithLocation];
                setRooms(updatedRooms);
                setRoomCount((prevCount) => prevCount + 1);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [locationId, buildingId, rooms, boxes, layout, saveLayout, layoutId, t]
        );

        const handleHorseRemove = useCallback(
            async (instanceId) => {
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse: null } : box
                );

                setBoxes(updatedBoxes);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId, t]
        );

        const handleRoomRemove = useCallback(
            async (instanceId) => {
                const updatedRooms = rooms.filter((room) => room.instanceId !== instanceId);
                setRooms(updatedRooms);
                setRoomCount((prevCount) => prevCount - 1);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [rooms, boxes, layout, saveLayout, layoutId, locationId, buildingId, t]
        );

        // Handler to create a new layout
        const handleCreateLayout = useCallback(async () => {
            setIsCreatingLayout(true);
            try {
                if (boxTypes.length === 0) {
                    console.warn('Box types are not loaded yet.');
                    return;
                }

                const initialBoxes = boxTypes.flatMap((box) =>
                    Array.from({ length: Number(box.amount) }, (_, index) => ({
                        ...box,
                        width: Number(box.width) || 1,
                        length: Number(box.length) || 1,
                        instanceId: `${box.id}-${index}`,
                        type: 'box',
                        horse: null,
                    }))
                );

                const buildingWidthMeters = Number(buildingWidth) || 12;
                let currentX = 0;
                let currentY = 0;
                let maxRowHeight = 0;

                const initialLayout = initialBoxes.map((box, index) => {
                    const boxWidth = Number(box.width) || 1;
                    const boxLength = Number(box.length) || 1;

                    if (currentX + boxWidth > buildingWidthMeters) {
                        // Move to next row
                        currentX = 0;
                        currentY += maxRowHeight;
                        maxRowHeight = 0;
                    }

                    const layoutItem = {
                        i: box.instanceId,
                        x: currentX,
                        y: currentY,
                        w: boxWidth,
                        h: boxLength,
                        static: false,
                    };

                    currentX += boxWidth;
                    if (boxLength > maxRowHeight) {
                        maxRowHeight = boxLength;
                    }

                    return layoutItem;
                });

                const newLayoutData = {
                    layout: initialLayout,
                    boxes: initialBoxes,
                    rooms: rooms,
                };

                const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, {
                    layoutData: newLayoutData,
                    locationId,
                    buildingId,
                });

                setLayoutId(savedLayout.id);
                setLayout(initialLayout);
                setBoxes(initialBoxes);
                setBoxCount(initialBoxes.length);
                setIsLayoutNotFound(false);
            } catch (error) {
                console.error(t('FarmLayout.ErrorCreatingLayout'), error);
            } finally {
                setIsCreatingLayout(false);
            }
        }, [boxTypes, apiKey, organizationId, jwtToken, locationId, buildingId, rooms, t, buildingWidth]);

        const buildingWidthMeters = Number(buildingWidth) || 12;
        const pixelsPerMeter = 30; // Adjust as needed

        return (
            <>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        {t('FarmLayout.DesignLayout')}
                    </Typography>
                    <Box>
                        <Button variant="contained" onClick={openRoomCreationModal} sx={{ mr: 1 }}>
                            {t('FarmLayout.CreateNewRoom')}
                        </Button>
                        <Button variant="contained" onClick={openPrintModal} sx={{ mr: 1 }}>
                            {t('FarmLayout.Print')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleResetLayout}>
                            {t('FarmLayout.Reset')}
                        </Button>
                    </Box>

                </Box>
                <div
                    id="grid-container"
                    style={{
                        backgroundColor: '#f9f9f9',
                        position: 'relative',
                        boxSizing: 'border-box',
                        userSelect: 'none',
                    }}
                >
                    {isLayoutNotFound ? (
                        <Box textAlign="center" mt={4}>
                            <Typography variant="h6" gutterBottom>
                                {t('FarmLayout.LayoutNotFound')}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateLayout}
                                disabled={isCreatingLayout}
                            >
                                {isCreatingLayout ? t('FarmLayout.CreatingLayout') : t('FarmLayout.CreateLayout')}
                            </Button>
                        </Box>
                    ) : !isInitializing && layout.length > 0 ? (
                        <ReactGridLayout
                            className="layout"
                            layout={layout}
                            cols={Math.ceil(buildingWidthMeters)}
                            rowHeight={pixelsPerMeter}
                            width={buildingWidthMeters * pixelsPerMeter}
                            onLayoutChange={onLayoutChange}
                            useCSSTransforms={true}
                            isBounded={false}
                            draggableCancel=".non-draggable"
                        >
                            {filteredItems.map((item) => (
                                <div
                                    key={item.instanceId}
                                    data-grid={layout.find((l) => l.i === item.instanceId)}
                                >
                                    {item.type === 'box' ? (
                                        <BoxItem
                                            box={item}
                                            handleHorseDrop={handleHorseDrop}
                                            handleHorseRemove={handleHorseRemove}
                                        />
                                    ) : (
                                        <RoomItem room={item} handleRoomRemove={handleRoomRemove} />
                                    )}
                                </div>
                            ))}
                        </ReactGridLayout>
                    ) : (
                        !isInitializing && (
                            <Typography variant="body1">
                                {t('FarmLayout.PleaseSelectBuilding')}
                            </Typography>
                        )
                    )}
                </div>

                {/* Existing Modals */}
                <PrintPreviewModal
                    isOpen={isPrintModalOpen}
                    onClose={closePrintModal}
                    layout={layout}
                    boxes={boxes}
                    rooms={rooms}
                    isInitializing={isInitializing}
                    filteredItems={filteredItems}
                />

                <RoomCreationModal
                    isOpen={isModalOpen}
                    onClose={closeRoomCreationModal}
                    onCreateRoom={handleCreateRoom}
                />
            </>
        );
    }
);

export default FarmLayout;
