// src/components/OrgTabs/DataExport/DataExport.js

import React from 'react';
import { Box } from '@mui/material';
import SettingsDisplay from './DataExport/SettingsDisplay';
import DataExportForm from './DataExport/DataExportForm';

const DataExport = () => {
    return (
        <Box>
            <SettingsDisplay />
            <DataExportForm />
        </Box>
    );
};

export default DataExport;
