// src/Routes/AppRoutes.js
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import HorsePage from '../pages/HorsePage';
import HorseInfoPage from '../pages/HorseInfoPage';
import OwnersPage from '../pages/OwnersPage';
import GuidingMachinePage from '../pages/GuidingMachinePage/index';
import OrganizationSettings from '../pages/OrganizationSettings';
import SignUp from '../components/SignUp';
import AuthPage from '../pages/AuthPage';
import ForgotPassword from '../components/ForgotPassword';
import Resetpassword from '../components/resetpassword';
import SelfSignupPage from '../pages/SelfSignupPage';
import RequireAuth from './RequireAuth';
import VeterinarianForm from '../components/OrgTabs/VeterinarianForm';
import FerryForm from '../components/OrgTabs/FerryForm';
import VaccinationPage from '../pages/VaccinationPage';
import VeterinarianVisitPage from '../pages/VeterinarianVisitPage';
import InvoicesListPage from '../pages/InvoicesListPage';
import InvoiceDetailPage from '../pages/InvoiceDetailPage/index';
import BoxTypeForm from '../components/OrgTabs/BoxTypeForm';
import PricingForm from '../components/OrgTabs/PricingForm';
import FarrierVisitPage from '../pages/FarrierVisitPage';
import OwnerDetailsPage from '../pages/ownerDetailsPage/OwnerDetails';
import ExpenseListPage from '../pages/ExpenseListPage';
import ExpenseDetailsPage from '../pages/expenseDetailsPage/ExpenseDetailsPage';
import InventoryManagement from '../pages/inventoryManagement/inventoryManagementPage';
import ItemInfoPage from '../pages/inventoryManagement/itemManagement/ItemInfoPage';
import InventoryTransactionInfoPage from '../pages/inventoryManagement/inventoryManagement/InventoryTransactionInfoPage';
import SalesProcessPage from '../pages/SalesProcessPage';
import SalesOrderDetailPage from '../pages/SalesOrderDetailPage/SalesOrderDetailPage';
import DeliveryNoteDetailPage from '../pages/DeliveryNotesDetailPage/DeliveryNoteDetailPage';
import CostEstimationDetailPage from '../pages/CostEstimationDetailPage/CostEstimationDetailPage';
import PaymentDetailPage from '../pages/incomingPaymentPage/PaymentDetailPage';
import UserForm from '../components/OrgTabs/UserForm';
import AuthRedirect from '../components/AuthRedirect';
import GuidedSignupPage from '../pages/auth/GuidedSignupPage';
import HorseDetailPage from '../pages/HorseDetailPage';
import DocumentsPage from '../pages/DocumentsPage';
import DocumentsDetailsPage from '../pages/DocumentsDetailsPage/DocumentsDetailsPage';
import DewormingPage from '../pages/DewormingPage';
import DewormingDetailsPage from '../pages/dewormingDetailsPage/dewormingDetailsPage';
import PaymentReminder from '../pages/PaymentReminders/PaymentReminder';
import RidingLessonIndex from '../pages/RidingLessons/RidingLessonIndex';
import InvitePage from '../pages/InvitePage';
import OAuthRedirect from '../pages/OAuthRedirect';
import ActivityPage from '../pages/ActivityPage';
import DeliveryPlanOverviewPage from '../pages/DeliveryPlanning/DeliveryPlanOverviewPage';
import DeliveryPlanDetailsPage from '../pages/DeliveryPlanning/DeliveryPlanDetailsPage';
import DeliveryPlanEditPage from '../pages/DeliveryPlanning/DeliveryPlanEditPage';
import DeliveryPlanningPage from '../pages/DeliveryPlanningPage';
import RouteEditPage from '../pages/DeliveryPlanning_Routes/RouteEditPage';
import RouteDetailsPage from '../pages/DeliveryPlanning_Routes/RouteDetailsPage';
import EmployeeManagementPage from '../pages/EmployeeManagement/EmployeeManagementPage';
import EmployeeDetailPage from '../pages/EmployeeManagement/EmployeeDetailPage';
import EmployeeViewPage from '../pages/EmployeeManagement/EmployeeViewPage';
import WorkingTimePage from '../pages/WorkingTimesPage/WorkingTimesPage';
import WorkingTimeDetailPage from '../pages/WorkingTimesPage/WorkingTimeDetailPage';

function AppRoutes() {

    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/auth/selfSignup" element={<SelfSignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<Resetpassword />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route path="/auth/redirect" element={<AuthRedirect />} />

            {/* Protected Routes */}
            <Route path="/" element={<RequireAuth />}>
                <Route index element={<Home />} />
                <Route path="/auth/guided-signup" element={<GuidedSignupPage />} />
                <Route path="organization-settings" element={<OrganizationSettings />} />
                <Route path="organization-settings/veterinarians/new" element={<VeterinarianForm />} />
                <Route path="organization-settings/veterinarians/edit/:id" element={<VeterinarianForm />} />
                <Route path="organization-settings/ferries/new" element={<FerryForm />} />
                <Route path="organization-settings/ferries/edit/:id" element={<FerryForm />} />
                <Route path="organization-settings/boxes/new" element={<BoxTypeForm />} />
                <Route path="organization-settings/boxes/edit/:id" element={<BoxTypeForm />} />
                <Route path="organization-settings/pricing/new" element={<PricingForm />} />
                <Route path="organization-settings/pricing/edit/:id" element={<PricingForm />} />
                <Route path="organization-settings/users/new" element={<UserForm />} />
                <Route path="organization-settings/users/edit/:id" element={<UserForm />} />
                <Route path="horses" element={<HorsePage />} />
                <Route path="dewormings" element={<DewormingPage />} />
                <Route path="dewormings/detail/:dewormingId" element={<DewormingDetailsPage />} />
                <Route path="documents" element={<DocumentsPage />} />
                <Route path="documents/details/:documentId" element={<DocumentsDetailsPage />} />
                <Route path="sales-process" element={<SalesProcessPage />} />
                <Route path="sales-process/costEstimations/detail/:estimationId" element={<CostEstimationDetailPage />} />
                <Route path="sales-process/sales-orders/detail/:salesOrderId" element={<SalesOrderDetailPage />} />
                <Route path="sales-process/deliveryNotes/detail/:deliveryNoteId" element={<DeliveryNoteDetailPage />} />
                <Route path="sales-process/invoices/detail/:invoiceId" element={<InvoiceDetailPage />} />
                <Route path="invoices" element={<InvoicesListPage />} />
                <Route path="invoices/detail/:invoiceId" element={<InvoiceDetailPage />} />
                <Route path="expenses" element={<ExpenseListPage />} />
                <Route path="expenses/detail/:expenseId" element={<ExpenseDetailsPage />} />
                <Route path="horses/:id" element={<HorseInfoPage />} />
                <Route path="horses/detail/:id" element={<HorseDetailPage />} />
                <Route path="horses/create/:id" element={<HorseInfoPage />} />
                <Route path="horses/edit/:id" element={<HorseInfoPage />} />
                <Route path="vaccinations" element={<VaccinationPage />} />
                <Route path="veterinarian-visits" element={<VeterinarianVisitPage />} />
                <Route path="farrier-visits" element={<FarrierVisitPage />} />
                <Route path="owners" element={<OwnersPage />} />
                <Route path="owners/new" element={<OwnerDetailsPage />} />
                <Route path="owners/:ownerId" element={<OwnerDetailsPage />} />
                <Route path="guidingmachine" element={<GuidingMachinePage />} />
                <Route path="inventory" element={<InventoryManagement />} />
                <Route path="inventory/item/:itemId" element={<ItemInfoPage />} />
                <Route path="inventory/transaction/:transactionId" element={<InventoryTransactionInfoPage />} />
                <Route path="/payments/detail/:paymentId" element={<PaymentDetailPage />} />
                <Route path="payment-reminders/:paymentReminderId" element={<PaymentReminder />} />
                <Route path="Bookings" element={<RidingLessonIndex />} />
                <Route path="invite" element={<InvitePage />} />
                <Route path="OAuthRedirect" element={<OAuthRedirect />} />
                <Route path="horseActivity" element={<ActivityPage />} />
                <Route path="deliveryPlanning" element={<DeliveryPlanningPage />} />
                <Route path="deliveryPlanning/delivery-plans" element={<DeliveryPlanOverviewPage />} />
                <Route path="deliveryPlanning/delivery-plans" element={<DeliveryPlanOverviewPage />} />
                <Route path="deliveryPlanning/delivery-plans/edit/:id" element={<DeliveryPlanEditPage />} />
                <Route path="deliveryPlanning/delivery-plans/:id" element={<DeliveryPlanDetailsPage />} />
                <Route path="deliveryPlanning/routes/edit/:id" element={<RouteEditPage />} />
                <Route path="deliveryPlanning/routes/overview/:id" element={<RouteDetailsPage />} />
                <Route path="employees" element={<EmployeeManagementPage />} />
                <Route path="employees/view/:employeeId" element={<EmployeeViewPage />} />
                <Route path="employees/detail/:id" element={<EmployeeDetailPage />} />
                <Route path="working-times" element={<WorkingTimePage />} />
                <Route path="working-times/detail/:workingTimeId" element={<WorkingTimeDetailPage />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;