// src/components/OrgTabs/DataExport/SettingsDisplay.js

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { fetchTaxSettings } from '../../../services/TaxSettingsService';

const SettingsDisplay = () => {
    const { apiKey, organizationId, token } = useAuth();
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const result = await fetchTaxSettings(apiKey, organizationId, token);
                setSettings(result);
            } catch (error) {
                console.error('Error fetching tax settings:', error);
            }
        };

        getSettings();
    }, [apiKey, organizationId, token]);

    if (!settings) {
        return <Typography>Loading settings...</Typography>;
    }

    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h6">Company Legal and Tax Settings</Typography>
            <Typography>Country: {settings.country}</Typography>
            <Typography>Organization Type: {settings.orgType}</Typography>
            <Typography>Currency: {settings.currency}</Typography>
            {settings.region && <Typography>Region: {settings.region}</Typography>}
            <Typography>VAT Rate: {settings.vatRate}%</Typography>
            <Typography>
                Corporate Tax Rate: {settings.corporateTaxRate}%
            </Typography>
        </Box>
    );
};

export default SettingsDisplay;
