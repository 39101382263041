// src/components/OrgTabs/DataExport/MockData.js

export const getInvoicesForExport = (country) => {
    // Mocked invoices, including your examples and additional data
    const invoices = [
        {
            id: 'a00a2482-21f8-4ae3-baba-3e1230c028cf',
            invoiceNumber: 'AR20241000083',
            customerName: 'Jin Yuan Lee',
            issueDate: '2024-11-29T12:35:21.923Z',
            totalNetAmount: 566.67,
            taxAmount: 113.33, // Corrected to 20% of net amount
            totalAmount: 680.00,
            customerAccount: '20001', // Optional: Specific customer account
            rows: [
                {
                    description: 'Gin Tonic',
                    netPrice: '566.67',
                    taxRate: 20,
                },
            ],
        },
        {
            id: '17c7bd3a-2a45-4f25-bef0-4dc511cf9f6a',
            invoiceNumber: 'AR20241000069',
            customerName: 'Sam TTA',
            issueDate: '2024-09-09T16:25:08.000Z',
            totalNetAmount: 50.00,
            taxAmount: 10.00,
            totalAmount: 60.00,
            customerAccount: '20002',
            rows: [
                {
                    description: 'Dienstleistungen Buchhaltung Liter Service kombi',
                    netPrice: '50.00',
                    taxRate: 20,
                },
            ],
        },
        {
            id: '5054b78a-3b9f-421b-b90a-8dcc0f7dfd81',
            invoiceNumber: 'AR20241000048',
            customerName: 'Max Mustermann',
            issueDate: '2024-06-01T11:00:00.684Z',
            totalNetAmount: 416.67,
            taxAmount: 83.33, // Corrected to 20% of net amount
            totalAmount: 500.00,
            customerAccount: '20003',
            rows: [
                {
                    description: 'Truthan',
                    netPrice: 416.67,
                    taxRate: 20,
                },
            ],
        },
        // Additional mocked invoices
        {
            id: 'b1234567-89ab-cdef-0123-456789abcdef',
            invoiceNumber: 'AR20241000084',
            customerName: 'Anna Schmidt',
            issueDate: '2024-12-01T09:15:00.000Z',
            totalNetAmount: 250.00,
            taxAmount: 50.00,
            totalAmount: 300.00,
            customerAccount: '20004',
            rows: [
                {
                    description: 'Consulting Services',
                    netPrice: 250.00,
                    taxRate: 20,
                },
            ],
        },
        {
            id: 'c2345678-90ab-cdef-1234-567890abcdef',
            invoiceNumber: 'AR20241000085',
            customerName: 'Tech Solutions GmbH',
            issueDate: '2024-12-05T14:30:00.000Z',
            totalNetAmount: 1000.00,
            taxAmount: 100.00, // Assuming 10% VAT
            totalAmount: 1100.00,
            customerAccount: '20005',
            rows: [
                {
                    description: 'Software License',
                    netPrice: 1000.00,
                    taxRate: 10,
                },
            ],
        },
        {
            id: 'd3456789-01ab-cdef-2345-678901abcdef',
            invoiceNumber: 'AR20241000086',
            customerName: 'Global Enterprises',
            issueDate: '2024-12-10T11:00:00.000Z',
            totalNetAmount: 1500.00,
            taxAmount: 0.00, // Export sale, 0% VAT
            totalAmount: 1500.00,
            customerAccount: '20006',
            rows: [
                {
                    description: 'Export Goods',
                    netPrice: 1500.00,
                    taxRate: 0,
                },
            ],
        },
    ];

    // Filter or modify invoices based on country if necessary
    return invoices;
};
