// src/components/OrgTabs/DataExport/DataExportService.js

import { saveAs } from 'file-saver';
import { getInvoicesForExport } from './MockData';

export const exportData = (format, country) => {
    // Fetch invoices to export
    const invoices = getInvoicesForExport(country);

    if (format === 'DATEV') {
        const datevContent = convertInvoicesToDATEV(invoices);
        const blob = new Blob([datevContent], { type: 'text/plain;charset=ANSI;' });
        saveAs(blob, `DATEV_Export_${country}.csv`);
    } else if (format === 'CSV') {
        const csvContent = convertInvoicesToCSV(invoices);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `Data_Export_${country}.csv`);
    }
};

const convertInvoicesToDATEV = (invoices) => {
    const header = createDATEVHeader();
    const body = invoices.map((invoice) => createDATEVDataLines(invoice)).flat();
    return [header, ...body].join('\r\n');
};

const createDATEVHeader = () => {
    const fields = [
        '"EXTF"', // Export Identifier
        '"510"', // Version Number
        '"01012024"', // Export Date (e.g., January 1, 2024)
        '"YourSoftwareName"',
        '"Version"',
        '"OrganisationID"',
        '""', // Additional Info
    ];
    return fields.join(';');
};

const createDATEVDataLines = (invoice) => {
    const lines = [];

    // Sales Entry (Credit Sales Revenue, Debit Accounts Receivable)
    const salesAmount = invoice.totalNetAmount.toFixed(2).replace('.', ',');
    const date = formatDate(invoice.issueDate);
    const bookingText = `Invoice ${invoice.invoiceNumber} to ${invoice.customerName}`;

    // Credit Sales Revenue
    lines.push(
        [
            `"${salesAmount}"`, // Umsatz (Amount)
            `"H"`, // Soll/Haben Kennzeichen (Credit)
            `"EUR"`, // WKZ Umsatz (Currency Code)
            '""', // Kurs
            '""', // Basis-Umsatz
            '""', // WKZ Basis-Umsatz
            `"4000"`, // Konto (Sales Revenue Account)
            `"${invoice.customerAccount || '20000'}"`, // Gegenkonto (Accounts Receivable)
            `"${getTaxKey(invoice.rows)}"`, // BU-Schlüssel (Tax Key)
            `"${date}"`, // Belegdatum (Document Date)
            `"${invoice.invoiceNumber}"`, // Belegfeld 1 (Document Number 1)
            '""', // Belegfeld 2
            '""', // Skonto
            `"${bookingText}"`, // Buchungstext (Booking Text)
        ].join(';')
    );

    // Debit Accounts Receivable
    lines.push(
        [
            `"${salesAmount}"`, // Umsatz (Amount)
            `"S"`, // Soll/Haben Kennzeichen (Debit)
            `"EUR"`, // WKZ Umsatz (Currency Code)
            '""', // Kurs
            '""', // Basis-Umsatz
            '""', // WKZ Basis-Umsatz
            `"${invoice.customerAccount || '20000'}"`, // Konto (Accounts Receivable)
            `"4000"`, // Gegenkonto (Sales Revenue)
            `"${getTaxKey(invoice.rows)}"`, // BU-Schlüssel (Tax Key)
            `"${date}"`, // Belegdatum
            `"${invoice.invoiceNumber}"`, // Belegfeld 1
            '""', // Belegfeld 2
            '""', // Skonto
            `"${bookingText}"`, // Buchungstext
        ].join(';')
    );

    // VAT Entry (if applicable)
    if (invoice.taxAmount > 0) {
        const taxAmount = invoice.taxAmount.toFixed(2).replace('.', ',');
        // Credit VAT Payable
        lines.push(
            [
                `"${taxAmount}"`, // Umsatz (Amount)
                `"H"`, // Soll/Haben Kennzeichen (Credit)
                `"EUR"`, // WKZ Umsatz (Currency Code)
                '""', // Kurs
                '""', // Basis-Umsatz
                '""', // WKZ Basis-Umsatz
                `"3500"`, // Konto (VAT Payable)
                `"${invoice.customerAccount || '20000'}"`, // Gegenkonto (Accounts Receivable)
                `"${getTaxKey(invoice.rows)}"`, // BU-Schlüssel (Tax Key)
                `"${date}"`, // Belegdatum
                `"${invoice.invoiceNumber}"`, // Belegfeld 1
                '""', // Belegfeld 2
                '""', // Skonto
                `"VAT for Invoice ${invoice.invoiceNumber}"`, // Buchungstext
            ].join(';')
        );

        // Debit Accounts Receivable with VAT
        lines.push(
            [
                `"${taxAmount}"`, // Umsatz (Amount)
                `"S"`, // Soll/Haben Kennzeichen (Debit)
                `"EUR"`, // WKZ Umsatz
                '""', // Kurs
                '""', // Basis-Umsatz
                '""', // WKZ Basis-Umsatz
                `"${invoice.customerAccount || '20000'}"`, // Konto (Accounts Receivable)
                `"3500"`, // Gegenkonto (VAT Payable)
                `"${getTaxKey(invoice.rows)}"`, // BU-Schlüssel
                `"${date}"`, // Belegdatum
                `"${invoice.invoiceNumber}"`, // Belegfeld 1
                '""', // Belegfeld 2
                '""', // Skonto
                `"VAT for Invoice ${invoice.invoiceNumber}"`, // Buchungstext
            ].join(';')
        );
    }

    return lines;
};

const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear());
    return `${day}${month}${year}`;
};

const getTaxKey = (rows) => {
    // Assuming all rows have the same tax rate
    const taxRate = rows[0].taxRate;
    // Map tax rates to DATEV tax keys
    const taxKeyMap = {
        20: '2', // 20% VAT
        10: '3', // 10% VAT
        0: '0',  // 0% VAT
    };
    return taxKeyMap[taxRate] || '0';
};

const convertInvoicesToCSV = (invoices) => {
    if (invoices.length === 0) return '';

    const headers = Object.keys(invoices[0]).join(';');
    const rows = invoices.map((invoice) =>
        Object.values(invoice)
            .map((value) => `"${value}"`)
            .join(';')
    );

    return [headers, ...rows].join('\r\n');
};
