// src/pages/EmployeeViewPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Paper, IconButton, LinearProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GenericDetailView from '../../components/GenericDetailView';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { fetchEmployeeById } from '../../services/EmployeeService';

const EmployeeViewPage = () => {
    const { employeeId } = useParams();
    const navigate = useNavigate();
    const [employee, setEmployee] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();
    const { apiKey, organizationId, token, userId } = useAuth();

    const employeeStructure = {
        sections: [
            {
                title: t('employeeDetail.personalInfo'),
                fields: [
                    { label: t('employeeDetail.firstName'), key: 'firstName' },
                    { label: t('employeeDetail.lastName'), key: 'lastName' },
                    { label: t('employeeDetail.dateOfBirth'), key: 'dateOfBirth' },
                ],
            },
            {
                title: t('employeeDetail.contactInfo'),
                fields: [
                    { label: t('employeeDetail.email'), key: 'email' },
                    { label: t('employeeDetail.phoneNumber'), key: 'phoneNumber' },
                    { label: t('employeeDetail.address'), key: 'address' },
                    { label: t('employeeDetail.workLocation'), key: 'workLocation' },
                ],
            },
            {
                title: t('employeeDetail.professionalInfo'),
                fields: [
                    { label: t('employeeDetail.department'), key: 'department' },
                    { label: t('employeeDetail.jobTitle'), key: 'jobTitle' },
                    { label: t('employeeDetail.dateOfHire'), key: 'dateOfHire' },
                ],
            },
            {
                title: t('employeeDetail.hrDetails'),
                fields: [
                    { label: t('employeeDetail.status'), key: 'status' },
                    { label: t('employeeDetail.employmentType'), key: 'employmentType' },
                    { label: t('employeeDetail.manager'), key: 'manager' },
                ],
            },
            {
                title: t('employeeDetail.compensation'),
                fields: [
                    { label: t('employeeDetail.baseSalary'), key: 'baseSalary' },
                    { label: t('employeeDetail.currency'), key: 'currency' },
                    { label: t('employeeDetail.paymentFrequency'), key: 'paymentFrequency' },
                ],
            },
            {
                title: t('employeeDetail.emergencyContact'),
                fields: [
                    { label: t('employeeDetail.emergencyContactName'), key: 'emergencyContactName' },
                    { label: t('employeeDetail.emergencyContactPhone'), key: 'emergencyContactPhone' },
                ],
            },
            {
                title: t('employeeDetail.additionalNotes'),
                fields: [
                    { label: t('employeeDetail.notes'), key: 'notes' },
                ],
            },
        ],
    };

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            setIsLoading(true);
            try {
                const data = await fetchEmployeeById(apiKey, organizationId, token, employeeId, userId);
                // data should match the backend model. Assuming it returns fields as set in backend code.

                setEmployee(data);

                setFormData({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    department: data.department,
                    jobTitle: data.jobTitle,
                    dateOfBirth: data.dateOfBirth
                        ? new Date(data.dateOfBirth).toLocaleDateString()
                        : '',
                    dateOfHire: data.dateOfHire
                        ? new Date(data.dateOfHire).toLocaleDateString()
                        : '',
                    status: data.status,
                    employmentType: data.employmentType,
                    manager: data.manager,
                    workLocation: data.workLocation,
                    address: data.street && data.city && data.state && data.zipCode,
                    emergencyContactName: data.emergencyContactName,
                    emergencyContactPhone: data.emergencyContactPhone,
                    notes: data.notes,
                    baseSalary: data.baseSalary,
                    currency: data.currency,
                    paymentFrequency: data.paymentFrequency,
                });
            } catch (error) {
                console.error('Error fetching employee:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (employeeId) {
            fetchEmployeeDetails();
        }
    }, [employeeId, apiKey, organizationId, token, userId]);

    return (
        <Box sx={{ p: 2 }}>
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                    <IconButton onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    {t('employeeDetail.viewEmployee')}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 4, mb: 2 }}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => navigate(`/employees/detail/${employeeId}`)}
                    >
                        {t('common.edit')}
                    </Button>
                </Box>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <GenericDetailView structure={employeeStructure} data={formData} />
                )}
            </Paper>
        </Box>
    );
};

export default EmployeeViewPage;
