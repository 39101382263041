import React, { useState, useEffect } from 'react';
import {
  Paper, Button, Box, LinearProgress, Typography, 
} from '@mui/material';
import { useTranslation } from 'react-i18next';
//import { fetchWorkingTimes } from '../../services/WorkingTimeService';
import GenericTableView from '../../components/GenericTableView';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useAuth } from '../../context/AuthContext';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



const WorkingTimesPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [workingTimes, setWorkingTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  const mockedData = [
    {
      id: 1,
      employeeName: 'John Doe',
      date: '2021-10-01',
      startTime: '08:00',
      endTime: '16:00',
    },
    {
      id: 2,
      employeeName: 'Jane Doe',
      date: '2021-10-01',
      startTime: '08:00',
      endTime: '16:00',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const data = await fetchWorkingTimes(apiKey, organizationId, token, userId);
        setWorkingTimes(mockedData);
      } catch (error) {
        console.error('Error fetching working times:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiKey, organizationId, token, userId]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const transformData = () => ({
    headers: [
      { key: 'employeeName', label: t('workingTimes.employeeName'), display: { value: true } },
      { key: 'date', label: t('workingTimes.date'), display: { value: true } },
      { key: 'startTime', label: t('workingTimes.startTime'), display: { value: true } },
      { key: 'endTime', label: t('workingTimes.endTime'), display: { value: true } },
    ],
    rows: workingTimes.map((wt) => ({
      id: wt.id,
      employeeName: { value: wt.employeeName },
      date: { value: wt.date },
      startTime: { value: wt.startTime },
      endTime: { value: wt.endTime },
      actions: [
        {
          icon: <EditIcon />,
          handler: () => navigate(`/working-times/detail/${wt.id}`),
          label: t('common.edit'),
        },
        {
          icon: <DeleteIcon />,
          handler: () => handleDelete(wt.id),
          label: t('common.delete'),
        },
      ],
    })),
  });

  const handleDelete = async (id) => {
    try {
      // await deleteWorkingTime(id, apiKey, organizationId, token, userId);
      setSnackbar({ open: true, message: t('workingTimes.deleteSuccess'), severity: 'success' });
    } catch (error) {
      console.error('Error deleting working time:', error);
      setSnackbar({ open: true, message: t('workingTimes.deleteError'), severity: 'error' });
    }
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">{t('workingTimes.title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/working-times/detail/new')}
        >
          {t('workingTimes.addWorkingTime')}
        </Button>
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <GenericTableView structure={transformData()} />
      )}
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default WorkingTimesPage;
