// src/components/OrgTabs/DataExport/DataExportForm.js

import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';
import { exportData } from './DataExportService';

const DataExportForm = () => {
    const [format, setFormat] = useState('DATEV'); // Default to DATEV
    const [country, setCountry] = useState('AT'); // Default to Austria

    const handleExport = () => {
        exportData(format, country);
    };

    return (
        <Box>
            <Typography variant="h6">Data Export Options</Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel id="country-select-label">Country</InputLabel>
                <Select
                    labelId="country-select-label"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                >
                    <MenuItem value="DE">Germany</MenuItem>
                    <MenuItem value="AT">Austria</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel id="format-select-label">Export Format</InputLabel>
                <Select
                    labelId="format-select-label"
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                >
                    <MenuItem value="DATEV">DATEV</MenuItem>
                    <MenuItem value="CSV">CSV</MenuItem>
                </Select>
            </FormControl>

            <Button variant="contained" onClick={handleExport}>
                Export Data
            </Button>
        </Box>
    );
};

export default DataExportForm;
