import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  MenuItem,
  Fab,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { fetchEmployees } from '../../services/EmployeeService';
import {
  fetchWorkingTimeEventsByEmployee,
  saveWorkingTimeEvent,
  updateWorkingTimeEvent,
  deleteWorkingTimeEvent,
} from '../../services/WorkingTimeEventService';
import SnackbarAlert from '../../components/SnackbarAlert';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';

// Set Moment.js to start weeks on Monday
moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const localizer = momentLocalizer(moment);

const WorkingTimeDetailPage = () => {
  const { t } = useTranslation();
  const { token, apiKey, organizationId, userId } = useAuth();
  const navigate = useNavigate();

  const [employeeId, setEmployeeId] = useState('');
  const [events, setEvents] = useState([]);
  const dateLocale = useDateLocale();
  const [employees, setEmployees] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [entryData, setEntryData] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('month');
  const [isEditing, setIsEditing] = useState(false); // To track if we're editing an existing event
  const [eventToEdit, setEventToEdit] = useState(null); // The event being edited

  const entryTypes = [
    { value: 'working', label: t('workingTimeDetail.workingHours') },
    { value: 'break', label: t('workingTimeDetail.break') },
    { value: 'vacation', label: t('workingTimeDetail.vacation') },
  ];

  // Define calendar formats to use 24-hour time
  const formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
    agendaTimeFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'MMM DD', culture) + ' – ' + localizer.format(end, 'MMM DD', culture),
    dayHeaderFormat: 'dddd, MMMM DD, YYYY',
  };

  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const data = await fetchEmployees(apiKey, organizationId, token, userId);
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
        setSnackbar({ open: true, message: t('workingTimeDetail.fetchEmployeesError'), severity: 'error' });
      }
    };
    fetchEmployeesData();
  }, [apiKey, organizationId, token, userId, t]);

  const handleEmployeeChange = async (e) => {
    const newEmployeeId = e.target.value;
    setEmployeeId(newEmployeeId);
    // Reset events when changing employee
    setEvents([]);
    // Fetch events for this employee
    try {
      const evts = await fetchWorkingTimeEventsByEmployee(apiKey, organizationId, token, userId, newEmployeeId);
      setEvents(evts.map(evt => ({
        ...evt,
        start: new Date(evt.start),
        end: new Date(evt.end)
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: t('workingTimeDetail.fetchEventsError'), severity: 'error' });
    }
  };

  const loadEvents = async (employeeId, viewDate, viewName) => {
    let startDate, endDate;

    if (viewName === 'month') {
      startDate = moment(viewDate).startOf('month').toISOString();
      endDate = moment(viewDate).endOf('month').toISOString();
    } else if (viewName === 'week') {
      startDate = moment(viewDate).startOf('week').toISOString();
      endDate = moment(viewDate).endOf('week').toISOString();
    }

    try {
      const fetchedEvents = await fetchWorkingTimeEventsByEmployee(
        apiKey,
        organizationId,
        token,
        userId,
        employeeId,
        startDate,
        endDate
      );
      setEvents(fetchedEvents.map(evt => ({
        ...evt,
        start: new Date(evt.start),
        end: new Date(evt.end)
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: t('workingTimeDetail.fetchEventsError'), severity: 'error' });
    }
  };

  useEffect(() => {
    if (employeeId) {
      loadEvents(employeeId, currentDate, currentView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, currentDate, currentView]);

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setIsEditing(false); // Reset editing state
    setEntryData({
      startTime: currentView === 'month' ? '09:00' : moment(slotInfo.start).format('HH:mm'),
      endTime: currentView === 'month' ? '17:00' : moment(slotInfo.end).format('HH:mm'),
      type: 'working',
      startDate: moment(slotInfo.start).startOf('day').toDate(),
      endDate: moment(slotInfo.end).startOf('day').toDate(),
    });
    // Do not open the modal automatically. User clicks the FAB to open it.
  };

  const handleSelectEvent = (event) => {
    setIsEditing(true);
    setEventToEdit(event);
    setEntryData({
      startTime: moment(event.start).format('HH:mm'),
      endTime: moment(event.end).format('HH:mm'),
      type: event.type,
      startDate: moment(event.start).toDate(),
      endDate: moment(event.end).toDate(),
    });
    setModalOpen(true);
  };

  const handleEntryChange = (e) => {
    const { name, value } = e.target;
    setEntryData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setEntryData((prev) => ({ ...prev, [name]: date }));
  };

  const handleSaveEntry = async () => {
    if (!employeeId) {
      setSnackbar({ open: true, message: t('workingTimeDetail.noEmployeeSelected'), severity: 'error' });
      return;
    }

    const isVacation = entryData.type === 'vacation';

    let eventData = {};

    if (isEditing && eventToEdit) {
      // Editing an existing event
      eventData = {
        ...eventToEdit,
        start: isVacation
          ? moment(entryData.startDate).startOf('day').toDate()
          : moment(entryData.startDate).set({
            hour: moment(entryData.startTime, 'HH:mm').hour(),
            minute: moment(entryData.startTime, 'HH:mm').minute(),
          }).toDate(),
        end: isVacation
          ? moment(entryData.endDate).toDate() // Assuming endDate is next day start for vacation
          : moment(entryData.endDate).set({
            hour: moment(entryData.endTime, 'HH:mm').hour(),
            minute: moment(entryData.endTime, 'HH:mm').minute(),
          }).toDate(),
        type: entryData.type,
        allDay: isVacation,
        title: isVacation ? 'Vacation' : `${entryData.startTime} - ${entryData.endTime}`,
      };
    } else if (selectedSlot) {
      // Creating a new event
      if (selectedSlot.slots && selectedSlot.slots.length > 1) {
        // Multiple day selection
        const newEvents = selectedSlot.slots.map((slotDate) => {
          let startDateTime, endDateTime;
          if (isVacation) {
            startDateTime = moment(slotDate).startOf('day').toDate();
            endDateTime = moment(slotDate).add(1, 'day').startOf('day').toDate();
          } else {
            startDateTime = moment(slotDate)
              .set({
                hour: moment(entryData.startTime, 'HH:mm').hour(),
                minute: moment(entryData.startTime, 'HH:mm').minute(),
              })
              .toDate();
            endDateTime = moment(slotDate)
              .set({
                hour: moment(entryData.endTime, 'HH:mm').hour(),
                minute: moment(entryData.endTime, 'HH:mm').minute(),
              })
              .toDate();
          }

          return {
            employeeId,
            start: startDateTime,
            end: endDateTime,
            type: entryData.type,
            allDay: isVacation,
            title: isVacation
              ? 'Vacation'
              : `${entryData.startTime} - ${entryData.endTime}`,
          };
        });

        try {
          // Persist events immediately
          const savedEvents = await Promise.all(
            newEvents.map(evt => saveWorkingTimeEvent(evt, apiKey, organizationId, token, userId))
          );
          // Convert start/end back to Date objects and add to state
          setEvents(prev => [
            ...prev,
            ...savedEvents.map(evt => ({
              ...evt,
              start: new Date(evt.start),
              end: new Date(evt.end),
            })),
          ]);
          setSnackbar({ open: true, message: t('workingTimeDetail.saveSuccess'), severity: 'success' });
        } catch (error) {
          console.error('Error saving events:', error);
          setSnackbar({ open: true, message: t('workingTimeDetail.saveError'), severity: 'error' });
        }

        setSelectedSlot(null);
        setModalOpen(false);
        return;
      } else {
        // Single day selection
        eventData = {
          employeeId,
          start: isVacation
            ? moment(entryData.startDate).startOf('day').toDate()
            : moment(entryData.startDate).set({
              hour: moment(entryData.startTime, 'HH:mm').hour(),
              minute: moment(entryData.startTime, 'HH:mm').minute(),
            }).toDate(),
          end: isVacation
            ? moment(entryData.endDate).toDate() // Assuming endDate is next day start for vacation
            : moment(entryData.endDate).set({
              hour: moment(entryData.endTime, 'HH:mm').hour(),
              minute: moment(entryData.endTime, 'HH:mm').minute(),
            }).toDate(),
          type: entryData.type,
          allDay: isVacation,
          title: isVacation ? 'Vacation' : `${entryData.startTime} - ${entryData.endTime}`,
        };
      }
    }

    if (isEditing && eventToEdit) {
      try {
        const updatedEvent = await updateWorkingTimeEvent(
          eventToEdit.id, // Assuming each event has a unique 'id'
          eventData,
          apiKey,
          organizationId,
          token,
          userId
        );
        // Update the event in the state
        setEvents(prev =>
          prev.map(evt => (evt.id === updatedEvent.id ? { ...updatedEvent, start: new Date(updatedEvent.start), end: new Date(updatedEvent.end) } : evt))
        );
        setSnackbar({ open: true, message: t('workingTimeDetail.updateSuccess'), severity: 'success' });
      } catch (error) {
        console.error('Error updating event:', error);
        setSnackbar({ open: true, message: t('workingTimeDetail.updateError'), severity: 'error' });
      }

      setEventToEdit(null);
      setIsEditing(false);
      setModalOpen(false);
    } else if (eventData.start && eventData.end) {
      // Saving a new single event
      try {
        const savedEvent = await saveWorkingTimeEvent(eventData, apiKey, organizationId, token, userId);
        setEvents(prev => [
          ...prev,
          {
            ...savedEvent,
            start: new Date(savedEvent.start),
            end: new Date(savedEvent.end),
          },
        ]);
        setSnackbar({ open: true, message: t('workingTimeDetail.saveSuccess'), severity: 'success' });
      } catch (error) {
        console.error('Error saving event:', error);
        setSnackbar({ open: true, message: t('workingTimeDetail.saveError'), severity: 'error' });
      }

      setSelectedSlot(null);
      setModalOpen(false);
    }
  };

  const handleDeleteEvent = async () => {
    if (!eventToEdit) return;

    try {
      await deleteWorkingTimeEvent(eventToEdit.id, apiKey, organizationId, token, userId);
      // Remove the event from state
      setEvents(prev => prev.filter(evt => evt.id !== eventToEdit.id));
      setSnackbar({ open: true, message: t('workingTimeDetail.deleteSuccess'), severity: 'success' });
    } catch (error) {
      console.error('Error deleting event:', error);
      setSnackbar({ open: true, message: t('workingTimeDetail.deleteError'), severity: 'error' });
    }

    setModalOpen(false);
    setEventToEdit(null);
    setIsEditing(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedSlot(null);
    setIsEditing(false);
    setEventToEdit(null);
  };

  const handleSave = async () => {
    // If you have additional save logic, implement here
    // For example, navigating back after saving
    navigate('/working-times');
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const eventStyleGetter = (event) => {
    let style = {};
    switch (event.type) {
      case 'working':
        style = { backgroundColor: '#64b5f6', color: 'white', border: '1px solid #1e88e5' };
        break;
      case 'vacation':
        style = { backgroundColor: '#ffb74d', color: 'white', border: '1px solid #f57c00' };
        break;
      case 'break':
        style = { backgroundColor: '#aed581', color: 'white', border: '1px solid #7cb342' };
        break;
      default:
        style = { backgroundColor: '#64b5f6', color: 'white', border: '1px solid #1e88e5' };
    }
    return { style };
  };

  // **Integrate dayStyleGetter from the first version**
  const dayStyleGetter = (date) => {
    if (currentView === 'month') {
      if (
        selectedSlot &&
        selectedSlot.slots &&
        selectedSlot.slots.some((slotDate) =>
          moment(slotDate).isSame(date, 'day')
        )
      ) {
        // Ensure slots are sorted
        const sortedSlots = [...selectedSlot.slots].sort((a, b) =>
          moment(a).diff(b)
        );
        const firstDate = moment(sortedSlots[0]);
        const lastDate = moment(sortedSlots[sortedSlots.length - 1]);
        const currentDateMoment = moment(date);

        const style = {
          backgroundColor: '#a5d6a7',
          border: 'none',
        };

        // Apply left border if it's the first day or first in its week
        if (
          currentDateMoment.isSame(firstDate, 'day') ||
          currentDateMoment.day() === 1 // Monday
        ) {
          style.borderLeft = '1px solid #388e3c';
        }

        // Apply right border if it's the last day or last in its week
        if (
          currentDateMoment.isSame(lastDate, 'day') ||
          currentDateMoment.day() === 0 // Sunday
        ) {
          style.borderRight = '1px solid #388e3c';
        }

        // Apply top border if it's the first row
        const isFirstRow =
          currentDateMoment.isSame(firstDate, 'day') ||
          !currentDateMoment.clone().subtract(1, 'day').isSame(moment(firstDate).startOf('week'), 'day');
        if (isFirstRow) {
          style.borderTop = '1px solid #388e3c';
        }

        // Apply bottom border if it's the last row
        const isLastRow =
          currentDateMoment.isSame(lastDate, 'day') ||
          !currentDateMoment.clone().add(1, 'day').isSame(moment(lastDate).endOf('week'), 'day');
        if (isLastRow) {
          style.borderBottom = '1px solid #388e3c';
        }

        return {
          style,
        };
      }
    }
    return {};
  };

  // **Integrate slotPropGetter from the first version**
  const slotPropGetter = (date) => {
    if (currentView !== 'month') {
      if (
        selectedSlot &&
        moment(date).isBetween(
          selectedSlot.start,
          selectedSlot.end,
          null,
          '[)'
        )
      ) {
        const slotDuration = 30; // Adjust if your slot duration is different
        const slotTime = moment(date);
        const startTime = moment(selectedSlot.start);
        const endTime = moment(selectedSlot.end);

        let style = {
          backgroundColor: '#a5d6a7',
          borderTop: 'none',
          borderBottom: 'none',
        };

        if (slotTime.isSame(startTime)) {
          style.borderTop = '1px solid #388e3c';
        }

        if (slotTime.clone().add(slotDuration, 'minutes').isSame(endTime)) {
          style.borderBottom = '1px solid #388e3c';
        }

        return {
          style: style,
        };
      }
    }
    return {};
  };

  // **Integrate renderSidePanel with summaries from the first version**
  const renderSidePanel = () => {
    const startOfPeriod = currentView === 'month' ? moment(currentDate).startOf('month') : moment(currentDate).startOf('week');
    const endOfPeriod = currentView === 'month' ? moment(currentDate).endOf('month') : moment(currentDate).endOf('week');

    const periodEvents = events.filter((e) =>
      moment(e.start).isBetween(startOfPeriod, endOfPeriod, null, '[)')
    );

    let totalWorkedHours = 0;
    let totalBreakHours = 0;
    let totalVacationDays = 0;

    periodEvents.forEach((event) => {
      const duration = moment(event.end).diff(moment(event.start), 'hours', true);
      if (event.type === 'working') {
        totalWorkedHours += duration;
      } else if (event.type === 'break') {
        totalBreakHours += duration;
      } else if (event.type === 'vacation') {
        totalVacationDays += 1;
      }
    });

    return (
      <div style={{ marginBottom: '1rem', padding: '0.5rem' }}>
        <Typography variant="h6">
          {currentView === 'month'
            ? `${t('workingTimeDetail.monthSummary')} ${startOfPeriod.format('MMMM YYYY')}`
            : `${t('workingTimeDetail.weekSummary')} ${startOfPeriod.format('DD MMM')} - ${endOfPeriod.format('DD MMM')}`}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalWorkedHours')}: {totalWorkedHours.toFixed(2)} {t('workingTimeDetail.hours')}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalBreakHours')}: {totalBreakHours.toFixed(2)} {t('workingTimeDetail.hours')}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalVacationDays')}: {totalVacationDays} {t('workingTimeDetail.days')}
        </Typography>
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {isEditing
            ? t('workingTimeDetail.editWorkingTime')
            : t('workingTimeDetail.addWorkingTime')}
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label={t('workingTimeDetail.employee')}
              name="employeeId"
              value={employeeId}
              onChange={handleEmployeeChange}
              fullWidth
            >
              {employees?.map((emp) => (
                <MenuItem key={emp.id} value={emp.id}>
                  {emp.firstName} {emp.lastName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              selectable={'ignoreEvents'}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectEvent}
              dayPropGetter={dayStyleGetter}
              slotPropGetter={slotPropGetter}
              step={30}
              timeslots={2}
              eventPropGetter={eventStyleGetter}
              style={{ height: 600 }}
              views={['week', 'month']}
              onNavigate={(date) => {
                setCurrentDate(date);
                if (employeeId) {
                  loadEvents(employeeId, date, currentView);
                }
              }}
              onView={(view) => {
                setCurrentView(view);
                if (employeeId) {
                  loadEvents(employeeId, currentDate, view);
                }
              }}
              formats={formats}
            />
          </Grid>
          <Grid item xs={4}>
            {renderSidePanel()}
          </Grid>
        </Grid>

        <Button variant="outlined" onClick={() => navigate('/working-times')} sx={{ mt: 2 }}>
          {t('common.cancel')}
        </Button>

        <SnackbarAlert
          open={snackbar.open}
          onClose={handleSnackbarClose}
          message={snackbar.message}
          severity={snackbar.severity}
        />

        <Modal open={modalOpen} onClose={handleModalClose}>
          <div
            style={{
              padding: '2rem',
              backgroundColor: '#fff',
              margin: '5% auto',
              maxWidth: '600px',
              borderRadius: '8px',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
            }}
          >
            <Typography variant="h6" gutterBottom>
              {isEditing
                ? t('workingTimeDetail.editEvent')
                : selectedSlot && selectedSlot.slots && selectedSlot.slots.length > 1
                  ? `${t('workingTimeDetail.enterTimeForMultipleDays')}`
                  : selectedSlot
                    ? `${t('workingTimeDetail.enterTimeFor')} ${moment(selectedSlot.start).format('LLL')}`
                    : t('workingTimeDetail.enterTime')}
            </Typography>
            <Grid container spacing={2}>
              {/* Start Date Picker */}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={t('workingTimeDetail.startDate')}
                  value={entryData.startDate || null}
                  onChange={(date) => handleDateChange('startDate', date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              {/* End Date Picker */}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={t('workingTimeDetail.endDate')}
                  value={entryData.endDate || null}
                  onChange={(date) => handleDateChange('endDate', date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </Grid>

            <TextField
              select
              label={t('workingTimeDetail.entryType')}
              name="type"
              value={entryData.type || ''}
              onChange={handleEntryChange}
              fullWidth
              sx={{ mt: 2 }}
            >
              {entryTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {entryData.type !== 'vacation' && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Start Time Picker */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('workingTimeDetail.startTime')}
                    type="time"
                    name="startTime"
                    value={entryData.startTime || ''}
                    onChange={handleEntryChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* End Time Picker */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('workingTimeDetail.endTime')}
                    type="time"
                    name="endTime"
                    value={entryData.endTime || ''}
                    onChange={handleEntryChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveEntry}
                  fullWidth
                >
                  {t('common.save')}
                </Button>
              </Grid>
              {isEditing && (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteEvent}
                    startIcon={<DeleteIcon />}
                    fullWidth
                  >
                    {t('common.delete')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Modal>

        {/* Always render the FAB, but disable it when no slot is selected */}
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            if (!selectedSlot && !isEditing) {
              setSnackbar({ open: true, message: t('workingTimeDetail.noDateSelected'), severity: 'error' });
              return;
            }
            setModalOpen(true);
          }}
          style={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <AddIcon />
        </Fab>
      </Paper>
    </LocalizationProvider>
  );
};

export default WorkingTimeDetailPage;
